import api from "@/plugins/api/EntryPoint";

export default {
  getFeedback(callBackFn) {
    api.get(`feedback/get-all-feedback-new`, callBackFn);
  },
  createTicket(ticket, callBackFn) {
    console.log("CHLENIX createTicket: " + JSON.stringify(ticket))
    return api.post(`feedback/add-new-feedback-new`, ticket, callBackFn);
  },
};
