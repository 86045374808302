import indicationsHistory from "@/plugins/api/factories/IndicationsHistory";

export const state = {
  filterValues: null,
  indicationsHistory: [],
  tariff: 1.68,
};

export const actions = {
  async getIndicationsHistory({ commit, state }) {
    await indicationsHistory.getIndications(state.filterValues, (response) => {
      console.log("INDICATION HISTORY DATE: " + JSON.stringify(response));
      commit("SET_INDICATIONS_HISTORY", response.indications);
    });
  },
};

export const mutations = {
  SET_TARIFF(state, tariff) {
    state.tariff = tariff;
  },
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues;
  },
  SET_INDICATIONS_HISTORY(state, indicationsHistory) {
    state.indicationsHistory = indicationsHistory;
  },
};
