<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
        span="24"
        class="
        itserve-center
        round-shadow-btn
        flex-display-fix
        container_width
        mt-15
      "
    >
    </van-col>
    <van-col span="24" class="itserve-center flex-display-fix">

      <itserve-route
          route-name="CalculationPage"
          :buttonName="$t('calculation.zone1Meterage')"
          :params="{
          meterage: 1,
        }"
          type="wide"
          class="less-tall-btn mb-25"
      ></itserve-route>

      <itserve-route
          route-name="CalculationPage"
          :buttonName="$t('calculation.zone2Meterage')"
          :params="{
          meterage: 2,
        }"
          type="wide"
          class="less-tall-btn mb-25"
      ></itserve-route>

      <itserve-route
          route-name="CalculationPage"
          :buttonName="$t('calculation.zone3Meterage')"
          :params="{
          meterage: 3,
        }"
          type="wide"
          class="less-tall-btn mb-25"
      ></itserve-route>

    </van-col>
  </van-row>
</template>
<script>
import ItserveRoute from "@/components/content/ItserveRoute";
import "vue-awesome/icons/phone";
import "vue-awesome/icons/comments";
export default {
  name: "SelectMeterage",
  components: { ItserveRoute},
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
  },
};
</script>
<style>
.write-ticket-message .info-content {
  text-align: center;
}
</style>
