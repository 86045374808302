export const state = {
  routingHistory: [],
  currentRoute: null,
};

export const mutations = {
  async SET_PREVIOUS_ROUTE(state, route) {
    if (
        state.routingHistory.length > process.env.VUE_APP_ROUTING_HISTORY_LENGTH
    ) {
      state.routingHistory.shift();
      if (await this.dispatch("BackButton/routeExists", route)) {
        state.routingHistory.push(route);
      }
    } else {
      if (await this.dispatch("BackButton/routeExists", route)) {
        state.routingHistory.push(route);
      }
    }
  },
  SET_CURRENT_ROUTE(state, routeName) {
    state.currentRoute = routeName;
  },
  SET_ROUTER_HISTORY(state, history) {
    state.routingHistory = history;
  },
};
export const actions = {
  routeExists({ state }, route) {
    for (let i in state.routingHistory) {
      if (
          state.routingHistory[i].name === route.name &&
          state.routingHistory[i].params === route.params
      ) {
        state.routingHistory.push(state.routingHistory.splice(i, 1)[0]);
        return false;
      }
    }
    return true;
  },
};
