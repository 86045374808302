import faq from "@/plugins/api/factories/Faq";

export const state = {
  faqSource: null,
  faqData: null,
  faqSearch: null,
};

export const actions = {
  getFaqData({ commit }, language) {
    faq.getFaq((response) => {
      commit("SET_FAQ_SOURCE", response);
      commit("SET_FAQ", response);
    }, language);
  },
  searchInFaq({ state, commit }, searchString) {
    if (searchString === null) {
      state.faqData = state.faqSource;
    } else {
      let res = [];
      for (let i in state.faqSource) {
        if (
            state.faqSource[i].question
                .toLowerCase()
                .match(searchString.toLowerCase()) !== null
        ) {
          res.push(state.faqSource[i]);
        }
      }
      commit("SET_FAQ_SEARCH_RES", res);
    }
  },
};

export const mutations = {
  SET_FAQ(state) {
    state.faqData = state.faqSource;
  },
  SET_FAQ_SOURCE(state, data) {
    state.faqSource = data;
  },
  SET_FAQ_SEARCH_RES(state, data) {
    state.faqData = data;
  },
};
