<template>
  <van-row justify="center" class="page-wrapper">
    <van-col
      span="24"
      class="
        itserve-center
        round-shadow-btn
        flex-display-fix
        container_width
        mt-15
      "
    >
      <info-message class="apply-loader flex-display-fix write-ticket-message"
        >{{ $t("writeTicket.ticketInfo") }}
      </info-message>
    </van-col>
    <van-col span="24" class="itserve-center flex-display-fix">
<!--      <itserve-route-->
<!--        route-name="FAQ"-->
<!--        :buttonName="$t('buttons.faq')"-->
<!--        type="wide"-->
<!--        class="less-tall-btn mb-25 mt-25"-->
<!--      ></itserve-route>-->
<!--      <itserve-route-->
<!--        route-name="AppSupport"-->
<!--        :buttonName="$t('buttons.appTechSupport')"-->
<!--        :params="{-->
<!--          // support: 'energy_support_kh',-->
<!--          account: $route.params.account,-->
<!--        }"-->
<!--        type="wide"-->
<!--        class="less-tall-btn mb-25"-->
<!--      ></itserve-route>-->
<!--      <itserve-route-->
<!--        route-name="AppSupport"-->
<!--        :buttonName="$t('buttons.appEnergoSbytSupport')"-->
<!--        :params="{-->
<!--          support: 'sales_support_kh',-->
<!--          account: $route.params.account,-->
<!--        }"-->
<!--        type="wide"-->
<!--        class="less-tall-btn mb-25"-->
<!--      ></itserve-route>-->
      <div class="itserve-center">
        <itserve-button
          @click="makeACall(phone)"
          :value="$t('buttons.calToOblEnergo')"
          type="primary"
          class="nav-btn tall-btn mb-25 apply-loader"
          small="font-size: 14px"
        ></itserve-button
        ><br />

<!--        <itserve-button-->
<!--          :value="$t('buttons.calToEnergoSbut')"-->
<!--          type="primary"-->
<!--          class="nav-btn mb-25 tall-btn apply-loader"-->
<!--          @click="makeACall('+380412559272')"-->
<!--        ></itserve-button>-->
      </div>
    </van-col>
  </van-row>
</template>
<script>
import ItserveButton from "@/components/forms/ItserveButton";
//import ItserveRoute from "@/components/content/ItserveRoute";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/phone";
import "vue-awesome/icons/comments";
export default {
  name: "WriteTicket",
  data() {
    return {
      phone: process.env.VUE_APP_CALL_PHONE,
    }
  },
  components: { InfoMessage, ItserveButton },
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
  },
};
</script>
<style>
.write-ticket-message .info-content {
  text-align: center;
}
</style>
