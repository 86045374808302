<template>
  <van-row class="page-wrapper" justify="center">
    <van-col class="itserve-center history-items" span="24">
      <template v-if="indicationsHistory.length">
        <template v-for="indication in indicationsHistory">
          <van-row
            v-bind:key="indication.id"
            class="indications-block place-content-center"
          >
            <van-col
              span="12"
              class="payment-history-left top-text-block apply-loader"
            >
              {{ $t("regionalEnergy.indicatorsHistorySearchResults.period") }}
            </van-col>
            <van-col
              span="12"
              class="payment-history-right top-text-block apply-loader"
            >
              {{ indication.value_date }}
            </van-col>

            <van-col span="12" class="payment-history-left apply-loader">
              {{
                $t("regionalEnergy.indicatorsHistorySearchResults.indications")
              }}
            </van-col>
            <van-col span="12" class="payment-history-right apply-loader">
              {{ indication.value1 }}
            </van-col>
            <template v-if="indication.value2">
              <van-col span="12" class="payment-history-left apply-loader">
              </van-col>
            <van-col span="12" class="payment-history-right apply-loader">
              {{ indication.value2 }}
            </van-col>
              </template>
            <template v-if="indication.value3">
              <van-col span="12" class="payment-history-left apply-loader">
              </van-col>
            <van-col span="12" class="payment-history-right apply-loader">
              {{ indication.value3 }}
            </van-col>
            </template>
          </van-row>
        </template>
      </template>
      <template v-else>
        <div class="place-content-center">
        <info-message class="apply-loader">
          {{ $t("global.noData") }}
        </info-message>
        </div>
      </template>
    </van-col>
  </van-row>
</template>
<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "PaymentHistorySearchResults",
  components: { InfoMessage },
  computed: {
    indicationsHistory() {
      console.log("chlenix indicationsHistory" + JSON.stringify(this.$store.state.IndicationsHistory.indicationsHistory));
      return this.$store.state.IndicationsHistory.indicationsHistory;
    },
    filterValues() {
      return this.$store.state.IndicationsHistory.filterValues;
    },
    tariff() {
      return this.$store.state.IndicationsHistory.tariff;
    },
  },
  data() {
    return {
      searchQuery: this.$route.params,
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      console.log(this.$route.params);
      if (this.filterValues === null) {
        await this.$store.commit("IndicationsHistory/SET_FILTER_VALUES", {
          ls: this.$route.params.account,
          start_date: this.$route.params.start_date,
          end_date: this.$route.params.end_date,
        });
      }
      await this.$store.dispatch("IndicationsHistory/getIndicationsHistory");
    },
  },
};
</script>
<style scoped>
.page-wrapper {
  background-color: #ffffff;
}
.top-text-block {
  padding-top: 8px;
}
.payment-history-left,
.payment-history-right {
  margin-top: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #0b0b0b;
}
.payment-history-right {
  text-align: right;
  padding-right: 15px;
  color: #777777;
}
.payment-history-left {
  text-align: left;
  padding-left: 15px;
}
.indications-block {
  border-bottom: 1px solid #777777;
  background-color: #ffffff;
}
.green-bold {
  color: #1aad62;
  font-weight: bold;
}
.indications-block:last-child {
  border-bottom: none !important;
}
</style>
