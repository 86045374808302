<template>
  <van-row class="white-page-bg payment-cb">
    <van-col span="24">
      <info-message class="itserve-center place-content-center">
        <img
            src="@/assets/images/action_success.png"
            alt="success-action"
        /><br />
        {{ $t("pay.paymentSuccessful") }}
      </info-message>
    </van-col>
  </van-row>
</template>

<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  components: { InfoMessage },
  name: "Success",
};
</script>

<style scoped></style>
