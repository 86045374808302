<template>
  <div class="itserve-center">
    <div class="place-content-center">
      <info-message class="apply-loader add-account-page-message">{{
        $t("autorizationPage.autorization")
      }}</info-message>
    </div>
    <van-col class="itserve-center mb-15" span="24">
      <itserve-button
        :value="$t('buttons.google')"
        type="primary"
        @click="googleSign"
        class="mt-15 apply-loader"
        :icon="true"
      >
        <img
          src="@/assets/images/icons8-google-48.png"
          width="30px"
          style="margin-left: 75px"
        />
      </itserve-button>
    </van-col>
    <template v-if="nativeData.platform === 'ios'">
      <van-col class="itserve-center mb-15" span="24">
        <itserve-button
          :value="$t('buttons.apple')"
          type="primary"
          @click="appleSign"
          class="mt-15 apply-loader"
          :icon="true"
        >
          <img
            src="@/assets/images/apple-logo-svgrepo-com.svg"
            width="30px"
            style="margin-left: 60px"
          />
        </itserve-button>
      </van-col>
    </template>
  </div>
</template>
<script>
import "@/plugins/nativescript-webview-interface.js";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "AutorizationPage",
  created() {},
  computed: {
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
  },
  methods: {
    googleSign() {
      this.nativeSendEvent("googleSignin");
      //this.$store.state.Auth.nativeData = null;
    },

    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
  },
  components: {
    InfoMessage,
    ItserveButton,
  },
};
</script>
