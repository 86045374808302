import api from "@/plugins/api/EntryPoint";
export default {
  DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
        splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  },
  async uploadPhoto(photoData) {
    const formData = new FormData();

    formData.append("userPhoto", photoData.data.userPhoto, "image.jpg");
    formData.append("account", photoData.data.account);
    formData.append("zone", photoData.zone);
    formData.append("zones", photoData.zones);

    return await api.post("indication/upload", formData, (res) => res, true);
  },
  async detectIndications(file) {
    const formData = new FormData();
    formData.append("imageToDetect", this.DataURIToBlob(file), "image.jpg");
    return await api.post(
        "electricity/get-number-from-image",
        formData,
        (res) => res,
        true
    );
  },
  async getLastIndications(params, callBackFn) {
    console.log("INDICATION HISTORY: " + JSON.stringify(params))
    await api.post("indication/history", params, callBackFn);
  },
  async sendIndications(indications, callBackFn) {
    return api.post("indication/input-new", indications, callBackFn);
  },
  async sendVerificationData(account, callBackFn) {
    return api.post(
        "electricity/register-ls-all-" + account.type,
        account,
        callBackFn
    );
  },
  async getShutdownInfo(account, callBackFn) {
    return api.get(
        `electricity/return-info-power-off?ls=${account}`,
        callBackFn
    );
  },
  async sendShutdownReport(report, callBackFn) {
    return api.post("electricity/check-power-off", report, callBackFn);
  },
};
