<template>
  <van-row class="page-wrapper add-account-page" justify="center">
    <van-col
        span="24"
        class="itserve-center round-shadow-btn flex-display-fix mb-25"
    >
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">{{
            $t("addAccount.lsNumber")
          }}
        </info-message>
      </div>
      <div class="place-content-center">
        <info-message class="apply-loader add-account-page-message">
          {{ $t("addAccount.waitLs") }}
        </info-message>
      </div>
      <van-form>
        <v-text-field
            :placeholder="$t('addAccount.lsNumber')"
            autocomplete="off"
            v-model="accountNumber"
            class="mt-10"
        ></v-text-field>
        <v-card v-if="listAccounts">
          <v-virtual-scroll
              height="200"
              item-height="64"
              :items="ACCOUNTS.ls"
          >
            <template v-slot="{ item }">
              <v-list-item :key="item.index">
                <v-list-item-content @click="addAccount(item.ls)">
                  {{ item.ls }}
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card>
      </van-form>
      <van-col class="itserve-center" span="24">
        <itserve-button
            :value="$t('buttons.addAccount')"
            type="primary"
            @click="addAccountNumber"
            class="mt-15 apply-loader"
        ></itserve-button>
      </van-col>
      <simple-dialog
          v-model="showDialog"
          :title="addAccountResponses[addAccountMode - 1]"
          :success="addAccountMode === 1"
          @change="goToHomePage"
      />
    </van-col>
  </van-row>
</template>
<script>
import Form from "vant/lib/form/index";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/phone";
// import {VCard, VDivider, VListItem, VListItemContent, VTextField, VVirtualScroll} from "vuetify/lib";
import SimpleDialog from "@/components/content/SimpleDialog";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "AddLs",
  data() {
    return {
      listAccounts: true,
      showBtn: false,
      showDialog: false,
      mode: 0,
      addAccountMode: 0,
      addAccountResponses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.alreadyAdded"),
      ],
      responses: [
        this.$t("addAccount.successfullyAdded"),
        this.$t("addAccount.alreadyAdded"),
        this.$t("addAccount.notFound"),
        this.$t("addAccount.oldVersionNotFound"),
      ],
    };
  },

  watch: {
    accountNumber() {
      this.accountsArr();

      let arr = this.ACCOUNTS;
      console.log("CHLENIX account " + JSON.stringify(arr));

      this.showBtn = true;
    },
  },

  computed: {
    ...mapGetters("ManualAddress", [
      "ACCOUNTS",
      "SELECTED_CITY",
      "SELECTED_STREET",
      "SELECTED_BUILDING",
      "SELECTED_APARTMENT",
    ]),

    accountNumber: {
      get() {
        return this.$store.state.Auth.accountNumber;
      },
      set(accountNumber) {
        this.$store.commit("Auth/SET_ACCOUNT_NUMBER", accountNumber);
      },
    },
  },

  components: {
    SimpleDialog,
    InfoMessage,
    ItserveButton,
    VanForm: Form,
    // VVirtualScroll,
    // VListItemContent,
    // VListItem,
    // VDivider,
    // VCard,
    // VTextField,
  },

  methods: {
    ...mapActions("ManualAddress", ["getAccountsArr"]),
    ...mapMutations("ManualAddress", ["setInfoAddedAddress"]),
    addAccountNumber() {
      this.$store.dispatch("Auth/storeNewAcont", (res) => {
        if (Object.keys(res).indexOf("mode") >= 0) {
          this.addAccountMode = res.mode;
          this.showDialog = true;
        } else {
          console.error(`Unknown response from server, mode doesn't exists ${JSON.stringify(res)}`
          );
        }
      });
    },

    accountsArr() {
      // let street = this.SELECTED_STREET.split(" ");
      //
      // if (street[2] === undefined) {
      //   street = street[1];
      // } else if (street[3] !== undefined) {
      //   street = street[1] + " " + street[2] + " " + street[3];
      // } else {
      //   street = street[1] + " " + street[2];
      // }
      let data = {
        city: this.SELECTED_CITY,
        street: this.SELECTED_STREET,
        building: this.SELECTED_BUILDING,
        apartment: this.SELECTED_APARTMENT,
      };
      this.getAccountsArr(data);
    },

    addAccount(accountNumber) {
      this.accountNumber = accountNumber;
      this.listAccounts = false;
    },
    goToHomePage() {
      if (this.addAccountMode === 1) {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/button/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
<style>
.v-text-field {
  margin-right: 10%;
  margin-left: 10%;
}

.v-text-field input {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}
</style>
