import payment from "@/plugins/api/factories/Payment";

export const state = {
  paymentResponse: null,
  paymentData: {
    amount: null,
    app_id: process.env.VUE_APP_EASYPAY_APP_ID,
    ls: null,
    save_card: false,
    card_token: null,
  },
  cards: [],
  paymentUrl: null,
  addCardUrl: null,
};

export const actions = {
  async getPaymentPage({ commit, state }) {
    console.log("CHLENIX state.paymentData: " + JSON.stringify(state.paymentData));
    return await payment.getPaymentUrl(state.paymentData, async (response) => {
      console.log("CHLENIX getPaymentUrl: " + response.url);
      commit("SET_PAYMENT_URL", response.url);
      commit("SET_PAYMENT_RESPONSE", response);
    });
  },
  async getCardsList({ commit }) {
    await payment.getEasyPayCardsList((response) => {
      commit("SET_PAYMENT_CARDS", response.cards ? response.cards : []);
    });
  },
  async deleteCard(store, cardToken) {
    return await payment.deleteCard({ card_token: cardToken }, (res) => {
      return res;
    });
  },
  async getSaveCardPage({ commit }) {
    await payment.getEasyPaySaveCardsPage((response) => {
      console.log("CHLENIX getEasyPaySaveCardsPage: " + response.url);
      commit("SET_ADD_CARD_URL", response.url);
    });
  },
};

export const mutations = {
  SET_PAYMENT_RESPONSE(state, response) {
    state.paymentResponse = response;
  },
  SET_PAYMENT_CARDS(state, cards) {
    state.cards = cards;
  },
  SET_CARD_TOKEN(state, token) {
    state.paymentData.card_token = token;
  },
  SET_SAVE_CARD(state, type) {
    state.paymentData.save_card = type;
  },
  SET_PAYMENT_URL(state, url) {
    state.paymentUrl = url;
  },
  UPDATE_AMOUNT(state, amount) {
    state.paymentData.amount = amount <= 0 ? 10 : amount;
  },
  SET_USER_LS(state, ls) {
    state.paymentData.ls = ls;
  },
  SET_ADD_CARD_URL(state, url) {
    state.addCardUrl = url;
    console.log("CHLENIX SET_ADD_CARD_URL: " + JSON.stringify(state.addCardUrl));
  },
};
