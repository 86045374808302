import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import { locales } from "@/locales/messages";
import Col from "vant/lib/col/index";
import Row from "vant/lib/row/index";
import VueI18n from "vue-i18n";
import store from "@/store";
import router from "@/router";
import AppBridge from "@/mixins/app-bridge";
import Icon from "vue-awesome/components/Icon";
import Vuetify from "@/plugins/vuetify";

// import Hotjar from "vue-hotjar";

global.getDateCustom = function (flag = "current") {
  let mm;
  let today = new Date();
  let dd = today.getDate();

  if (flag === "current") {
    mm = today.getMonth() + 1;
  } else {
    mm = today.getMonth();
    if(mm == 0){
      mm = 12;
    }
  }

  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  return (today = yyyy + "-" + mm + "-" + dd);
};

// Vue.use(Hotjar, {
//   id: "2666907",
//   isProduction: process.env.VUE_APP_API_ENVIRONMENT === "production",
// });

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.mixin(AppBridge);
const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
  messages: locales,
});
Vue.component("v-icon", Icon);
Vue.use(Col);
Vue.use(Row);

new Vue({
  Vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
